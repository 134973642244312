.vijay{
    position: relative;
    background-size: cover;
    height: 480px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.5s ease; /* Smooth transition for background color */
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position: center;
  }

  .vijay:hover{
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .vijay1{
    position: relative;
    background-size: cover;
    width: 480px;
    height: 480px;  
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.5s ease; /* Smooth transition for background color */
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-position: center;
  }
  
  #myinput {
    border-radius: 8px;
    height: 8px;
    outline: none;
    -webkit-appearance: none;
  }
  
  .volumeTab{
     display: none;
     transition: 10s ease-in;
  }
  .speaker:hover .volumeTab{
      display: block;
  }
  .pauseSpeaker:hover .volumeTab{
    display: block;
  }
  
  
  .speaker,.globe,.controls,.runningbar,.details,.timimg{
    display: none;
    transition: 4s ease-in;
  }
  
  
  input[type='range']{
    background-color: rgba(236, 232, 255, 0.4);
  }
  
  
  /* .vijay:hover + .speaker,.globe,.controls,.details,.runningbar,.timimg{
    display: block;
  } */
  
  
  .vijay:hover .speaker{
    display: block;
  }
  .vijay:hover .globe{
    display: block;
  }
  .vijay:hover .controls{
    display: block;
  }
  .vijay:hover .details{
    display: block;
  }
  .vijay:hover .runningbar{
    display: block;
  }
  .vijay:hover .timimg{
    display: block;
  }

  .commentInput:focus{
    outline: none;
  }
/* 
  .soundTab{
    display: none;
  } */

  .mediaVolume:hover .soundTab{
    background-color: 'red';
  }

  @media screen and (max-width : 996px){
    .wholeDiv{
      flex-direction: column !important;
    }
    .songInfo{
      display: none !important;
    }
    .song-data{
      width: 480px !important;
    }
    .songInfoTop{
      display: flex !important;
      margin-bottom: 20px !important;
      justify-content:space-between !important;
      width: 100%;
    }
    .userDetailAlign{
      display: flex;
      justify-content: center;
    }
    .topAlign{
      margin-top: 10px !important;
      display: flex !important;
      justify-content: center !important;
    }
    .songDetails{
      margin-top: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 0 10px !important;
      width: 100% !important;
    }
    .comment-align{
      flex-direction: column !important;
    }
  }

  @media screen and (max-width : 510px){
    .vijay{
      width: 350px !important;
      height:  350px !important;
    }
    .vijay1{
      width: 350px !important;
      height:  350px !important;
    }
    /* .gift-container{
      width: 350px !important;
      height: 350px !important;
    } */
    .topAlign{
      height: 350px !important;
    }
    .song-data{
      width: 350px !important;
    }
    .songInfoTop{
      width: 350px !important;
    }
    .all-controls{
      background: none !important;
      justify-content: left !important;
    }
  }

  .css-6hp17o-MuiList-root-MuiMenu-list{
    padding: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  
  
  
  
  
  
  
  