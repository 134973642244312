.tabs:hover{
    border: 1px solid #8A59FF;
}

.tabsHover:hover{
    background: 'rgb(69, 33, 182)';
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.textFiled:focus{
  outline: none;
}

.textFiled{
    padding: 10px;
    padding-top:10px;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    color:white !important
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    color: white !important;
    
    
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: white !important;
    color: white!important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: white !important;
} */
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline.containerrrr{
    border-color: white !important;
    color: white !important;

 } */

.textInputFiled{
    color: azure !important
}

.SingInIput:focus{
    background-color: red;}

    .textInputFiled {
       
        color: white !important
    }
    @keyframes bookOpen {
        0% {
          transform: scaleY(0) rotateX(90deg);
          opacity: 0;
        }
        100% {
          transform: scaleY(1) rotateX(0deg);
          opacity: 1;
        }
      }
      
      @keyframes bookClose {
        0% {
          transform: scaleY(1) rotateX(0deg);
          opacity: 1;
        }
        100% {
          transform: scaleY(0) rotateX(90deg);
          opacity: 0;
        }
      }
      
      /* Modal Styles */
     /* App.css */
@keyframes bookOpen {
    0% {
      transform: scaleY(0) rotateX(90deg);
      opacity: 0;
    }
    100% {
      transform: scaleY(1) rotateX(0deg);
      opacity: 1;
    }
  }
  
  @keyframes bookClose {
    0% {
      transform: scaleY(1) rotateX(0deg);
      opacity: 1;
    }
    100% {
      transform: scaleY(0) rotateX(90deg);
      opacity: 0;
    }
  }
  
  .bookOpen {
    animation: bookOpen 0.5s ease-out;
  }
  
  .bookClose {
    animation: bookClose 0.5s ease-out;
  }
  .loader {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
  }
  
  @keyframes shadowPulse {
    33% {
      background: #FFF;
      box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
    }
    66% {
      background: #FF3D00;
      box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    }
    100% {
      background: #FFF;
      box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
    }
  }
  @media (max-width: 768px) {
    .topnav {
      display: block; /* or flex */
    }
  }
  