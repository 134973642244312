body {
    background-color: #e5e5e5;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  
  /* .component {
    background-color: white;
    width: 25%;
    max-width: 600px;
    margin: 1em auto;
    padding-bottom: 2em;
    border: 0.1px solid black;
    border-radius: 10px;
  } */
  
  .musicCover {
    border-radius: 10%;
  }
  
  .playButton {
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
  }
  
  .subTitle {
    margin-top: -1em;
    color: #4f4f4f;
  }
  
  .time {
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    color: #828282;
    font-size: smaller;
  }
  
  .timeline {
    width: 80%;
    background-color: #27ae60;
  }
  
  input[type="range"] {
    background-color: #27ae60;
  }

  .inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile:focus + label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  .inputfile:focus{
    outline: none;
  }
  .inputfile + label * {
    pointer-events: none;
  }
  .form{
    background-color: #c6d8ec;
    color: #27ae60;
    
    
    
  }
  
  
  @media (max-width: 900px) {
    .component {
      width: 100%;
    }
  }