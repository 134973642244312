.edit-icon{
    visibility: hidden;
    color: gray;
  }

  .lyric-timimg-grid:hover{
    box-shadow: 0 1px 7px -2px rgba(0,0,0,.3);
  }

  .lyric-timimg-grid:hover .edit-icon{
    visibility: visible !important;
  }
  .lyric-timimg-grid:hover .timeControls{
    visibility: visible !important;
  }
  .lyric-timimg-grid input:focus{
    outline: none;
  }

  .input-focus:focus{
    outline: none;
    
  }

.lyric-input{
  width: 400px;
  height: 25;
  border: 0;
}

#color-palette{
  background-color: #fff;
  box-shadow: 0 1px 7px -2px rgba(0,0,0,.3);
  display: block;
  position: absolute;
  -webkit-transform: rotateX(36deg);
  transform: rotateX(360deg);
  -webkit-transform-origin: center top 0;
  transform-origin: center top 0;
  transition: all .5s ease 0s;
  visibility: visible;
  z-index: 999;
  width: 300px;
  display: inline-block;
}

.lyric-edit{
  visibility: hidden;
  color: gray;
  cursor: pointer;
}

.lyric-file:hover .lyric-edit{
  visibility: visible;
}

.lyric-file:hover .timeControls{
  visibility: visible !important;
}

.lyric-input:focus{
  outline: none;
}
.lyric-input:hover{
  background-color: rgba(211, 211, 211, 0.3);
}
/* .lyric-preview::-webkit-scrollbar {
  display: none;
} */
