.items--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #120B27;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 1rem;
    width: 150px;
    height: 50px;
      background-color: #120B27;
    
  }
  
 
  
  .item.active .arrow {
    background: #d68a3a;
  }
  
  .content {
    color: #120B27;
    position: absolute;
    z-index: 2;
   font-size: 1em;
  }
  .no_class{
    width: 20px;
    height: 20px;
    background-color: #50A0FF;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow {
    width: 100%;
    height: 50%;
    background: #FFFFFF;
  }
  
  .arrow.top {
    transform: skew(45deg, 0deg);
    border-left: 1px solid #120B27;
    border-right: 2px solid #413c52;
    border-top: 1px solid #0f0e0c;
    background-color: #120B27;
  }
  
  .arrow.bottom {
    transform: skew(-45deg, 0deg);
    border-left: 1px solid #120B27;
    border-right: 2px solid #413c52;
    border-bottom: 1px solid #120B27;
    background-color: #120B27;
  }