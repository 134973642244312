@keyframes slideIn {
    from {
      transform: translate(-50%, 50%);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    to {
      transform: translate(-50%, 100%);
      opacity: 0;
    }
  }
  
  .bookOpen {
    animation: slideIn 2s forwards;
  }
  
  .bookClose {
    animation: slideOut 1s forwards;
  }
  
  